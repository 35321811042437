<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="wrapper-box">
        <div class="main-content-wrapper">
          <div class="content-title">
            {{ currentNews.title }}
          </div>
          <div class="content-tips">
            <li>
              <span>{{ currentNews.pubdate }}</span>
            </li>
            <li>
              <Icon type="ios-book-outline" />
              <span class="amount">{{ currentNews.readAmount }}</span>
            </li>
            <li @click="thumbs(currentNews)" class="thumbs">
              <Icon
                type="ios-thumbs-up"
                v-if="thumbsList.includes(currentNews.id)"
              />
              <Icon type="ios-thumbs-up-outline" v-else />
              <span class="amount">{{ currentNews.thumbsAmount }}</span>
            </li>
          </div>
          <div class="content-box">
            <div class="c-html" v-html="currentNews.content"></div>
          </div>
          <div class="footer" v-if="currentNews.lastNews||currentNews.nextNews">
            <div class="half-ln" >
              <label for="" v-show="currentNews.lastNews.id">上一篇：</label>
              <span
                class="half-ln-title"
                @click="linkTo('/news/' + currentNews.lastNews.id)"
                >{{ currentNews.lastNews.title }}</span
              >
            </div>
            <div class="half-ln" >
              <label for="" v-show="currentNews.nextNews.id">下一篇：</label>
              <span
                class="half-ln-title"
                @click="linkTo('/news/' + currentNews.nextNews.id)"
                >{{ currentNews.nextNews.title }}</span
              >
            </div>
          </div>
        </div>
        <div class="relative-content-wrapper">
          <div class="relative-box" v-if="relativeNews.length>0">
            <div class="relative-title">
              <p>相关内容</p>
            </div>
            <div
              class="ele-r"
              v-for="(item, index) in relativeNews"
              :key="index"
            >
              <div class="r-title">
                <p @click="linkTo('/news/' + item.id)">{{ item.title }}</p>
              </div>
              <div class="r-description">
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Spin v-if="spin" fix size="large"></Spin>
    </div>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";
import thumbs from "@/libs/mixins/Thumbs.js"

export default {
  name: "newsList",
  components: {
    breadcrumbs,
  },
  props: {
    newsId: {
      type: String,
      required: true,
      default: "1",
    },
  },
  mixins:[thumbs],
  data() {
    return {
      thumbsList: [],
      readList:[],
      currentNews: {
        // id: 1,
        // title: "市场监督管理总局认可和检验检测监督管理司领导视察SRCC",
        // content:
        //   "2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。",
        // readAmount: 265,
        // thumbsAmount: 123,
        // pubdate: "2022-01-05",
        // lastNews: { title: "SRCC获批城市轨道交通车辆CURC认证资质", id: 3 },
        // nextNews: {
        //   title:
        //     "基于全生命周期的轨道交通装备健康管理关键技术研究等项目签约国家高速列车技术创新中心",
        //   id: 9,
        // },
      },
      relativeNews: [
        // {
        //   id: 1,
        //   title: "市场监督管理总局认可和检验检测监督管理司领导视察SRCC",
        //   description:
        //     "2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。",
        //   readAmount: 265,
        //   thumbsAmount: 123,
        //   pubdate: "2022-01-05",
        // },
        // {
        //   id: 2,
        //   title: "市场监督管理总局认可和检验检测监督管理司领导视察SRCC",
        //   description:
        //     "2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。",
        //   readAmount: 265,
        //   thumbsAmount: 123,
        //   pubdate: "2022-01-05",
        // },
        // {
        //   id: 3,
        //   title: "市场监督管理总局认可和检验检测监督管理司领导视察SRCC",
        //   description:
        //     "2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。",
        //   readAmount: 265,
        //   thumbsAmount: 123,
        //   pubdate: "2022-01-05",
        // },
      ],
      spin: false,
    };
  },
  methods: {
    linkTo: function (value) {
      this.spin = true;
      location.href = value;
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/content', {
          newsId: this.newsId,
          askData:'news',
        })
        .then(
          response => {
            this.relativeNews = response.data.relative;
            this.currentNews = response.data.current;
            this.spin = false;
          }
        );
    },
    readAlready(){
            if(!this.readList.includes(this.newsId)){
                this.readList.push(this.newsId);
                this.updateRead(1);
            }
            localStorage.setItem("readNews", JSON.stringify(this.readList));
        },
        updateRead(changeAmount) {
            this.$axios
              .post(this.$url + '/news_update', {
                askData:'read',
                newsId:this.newsId,
                changeAmount:changeAmount,
              })
              .then(
                response => {
                  this.currentNews.readAmount = response.data.res_record;
                }
              );
          },
  },
  computed: {
    breadcrumbsContents: function () {
      return [
        { text: "首页", url: "/" },
        { text: "公司新闻", url: "/news_list" },
        { text: this.currentNews.title, url: "/news/" + this.currentNews.id },
      ];
    },
  },
  mounted() {
    this.getData();
      this.thumbsList = JSON.parse(localStorage.getItem("thumbsNews"));
      if(this.thumbsList===null){
          this.thumbsList = [];
      }
      this.readList = JSON.parse(localStorage.getItem("readNews"));
      if(this.readList===null){
          this.readList = [];
      }
      setTimeout(()=>{
        this.readAlready();
      },3000);
  },
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
  padding-bottom: 70px;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.wrapper-box {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.footer {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.main-content-wrapper {
  width: 65%;
}

.relative-content-wrapper {
  width: 28%;
}

.content-title {
  color: #000;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0 0 30px 0;
}
.content-box {
  padding: 40px 0;
  font-size: 16px;
  text-align: justify;
  line-height: 2.7;
  color: #000000;
  font-family: Roboto, "Helvetica Neue", Arial, Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei",
    sans-serif;
}

.item-description {
  color: #999;
  font-size: 14px;
  text-indent: 28px;
  line-height: 28px;
  padding: 0px 0 20px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  height: 90px;
}

.content-tips {
  text-align: left;
  margin-left: -9px;
}

.content-tips li {
  list-style: none;
  display: inline;
  padding: 1px 10px;
  border-right: 1px solid #ddd;
}

.content-tips li.thumbs {
  cursor: pointer;
}

.content-tips li:hover {
  color: #b70005;
}

li:last-child {
  border-right: none;
}

.page-wrapper {
  text-align: center;
  padding: 50px 0;
}
.amount {
  position: relative;
  top: 1px;
  padding: 0 3px;
}

.footer {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  white-space: nowrap;
}

.half-ln {
  width: 47%;
  overflow: hidden;
  font-size: 16px;
  color: #222;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.half-ln-title {
  cursor: pointer;
}

.half-ln-title:hover {
  color: #b70005;
}

.relative-content-wrapper {
  background: #f3f7fa;
  padding: 40px;
}

.relative-title {
  font-size: 18px;
  margin-bottom: 25px;
  color: #000000;
}

.ele-r {
  padding: 0px 0 20px 0;
  border-bottom: 1px solid #ddd;
}

.ele-r:last-child {
  border-bottom: none;
}

.r-title p {
  font-size: 15px;
  color: #111;
  margin-bottom: 16px;
  margin-top: 26px;
  cursor: pointer;
  display: block;
}

.r-title p:hover {
  color: #b70005;
}

.r-description {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  height: 70px;
}

.r-description p {
  font-size: 14px;
  line-height: 1.64;
  color: #999999;
}
</style>